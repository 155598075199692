exports.shouldUpdateScroll = ({ routerProps }) => {
    const { location } = routerProps;
  
    // Check if it's the initial page load
    if (location.action === 'PUSH' && typeof window !== 'undefined') {
      // Scroll to the top of the page immediately
      window.scrollTo(0, 0);
    }
  
    return false;
  };