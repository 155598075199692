exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-actions-jsx": () => import("./../../../src/pages/Actions.jsx" /* webpackChunkName: "component---src-pages-actions-jsx" */),
  "component---src-pages-apartments-jsx": () => import("./../../../src/pages/Apartments.jsx" /* webpackChunkName: "component---src-pages-apartments-jsx" */),
  "component---src-pages-catering-jsx": () => import("./../../../src/pages/Catering.jsx" /* webpackChunkName: "component---src-pages-catering-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/Contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-menu-jsx": () => import("./../../../src/pages/Menu.jsx" /* webpackChunkName: "component---src-pages-menu-jsx" */),
  "component---src-pages-order-jsx": () => import("./../../../src/pages/Order.jsx" /* webpackChunkName: "component---src-pages-order-jsx" */),
  "component---src-pages-sections-actions-section-jsx": () => import("./../../../src/pages/sections/ActionsSection.jsx" /* webpackChunkName: "component---src-pages-sections-actions-section-jsx" */),
  "component---src-pages-sections-apartments-section-jsx": () => import("./../../../src/pages/sections/ApartmentsSection.jsx" /* webpackChunkName: "component---src-pages-sections-apartments-section-jsx" */),
  "component---src-pages-sections-catering-section-jsx": () => import("./../../../src/pages/sections/CateringSection.jsx" /* webpackChunkName: "component---src-pages-sections-catering-section-jsx" */),
  "component---src-pages-sections-intro-section-jsx": () => import("./../../../src/pages/sections/IntroSection.jsx" /* webpackChunkName: "component---src-pages-sections-intro-section-jsx" */)
}

